import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    baritasticProgram: {
      role_id: 0,
      parent_id: 0,
      username: '',
      owner_name: '',
      programmer_code: '',
      password: '',
      password1: '',
      email: '',
      event_email: '',
      new_email: '',
      title: '',
      first_name: '',
      last_name: '',
      third_checklist_name: "Weight Management",
      profile_image: '',
      phone: '',
      address: '',
      pin_code: '',
      city: '',
      map_address: '',
      latitude: '',
      longitude: '',
      practice: '',
      about: '',
      activation_key: '',
      security_status: 0,
      time_out: '',
      retries: 0,
      email_date: '',
      email_check: 0,
      show_name: 1,
      popup_check: 0,
      popup_msg: '',
      popup_check1: 0,
      popup_msg1: '',
      popup_status: 1,
      total_p: 0,
      year_p: 0,
      estimated_surgery: 0,
      total_new_p: 0,
      total_nutri_p: 0,
      pipe_update_date: '',
      reset_key: '',
      is_password_changed: 0,
      status: 1,
      created: '',
      modified: '',
      ip_address: '',
      ip_modified_at: '',
      tier: '',
      timezone: ''
    },
    sourceGetAll: '',
  },
  getters: {
    getBaritasticProgram(state) {
      return state.baritasticProgram
    },
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Get Program's Listing
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getBaritasticPrograms({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        if(state.sourceGetAll){
          state.sourceGetAll.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios.get(`${state.base_url}/api/admin/programs`, {params,cancelToken: state.sourceGetAll.token})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Export Programs Listing
     * @param context - Contains the commit and state object
     * @param params - Params for the API
     * @returns {Promise<unknown>}
     */
    _exportBaritasticPrograms({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/programs/export`, {params, responseType: "blob"})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Requested Baritastic Program
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedBaritasticProgram({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/programs/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Baritastic Program Password
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateProgramPassword({state, dispatch}, {payload}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/programs/update-password`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Delete Program(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteBaritasticProgram({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/programs/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Update Program(s) status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusProgram({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/programs/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Store Baritastic Program
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeBaritasticProgram({state, dispatch}, {fd}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/programs/store`, fd)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Baritastic Program
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateBaritasticProgram({state, dispatch}, {fd,id}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/programs/update/${id}`, fd)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Baritastic Program with Patient Tags
     * @param context - Contains the commit and state object
     * @returns {Promise<unknown>}
     * @private
     */
    _getProgramsWithPatientTags({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/programs/with-patient-tags`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  }
};
