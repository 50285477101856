import BaseInput from '@/components/Inputs/BaseInput.vue';
import BaseSelect from '@/components/Inputs/BaseSelect.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Card from '@/components/Cards/Card.vue';
import Modal from '@/components/Modal.vue';
import StatsCard from '@/components/Cards/StatsCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import Editor from '@tinymce/tinymce-vue'
// import Badge from '@/components/Badge.vue';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseRadio from "@/components/Inputs/BaseRadio";
import BaseProgress from "@/components/BaseProgress";
import BasePagination from "@/components/BasePagination";
import BaseItemPerPage from "@/components/BaseItemPerPage";
import BaseAlert from "@/components/BaseAlert";
import BaseNav from "@/components/Navbar/BaseNav";
import BaseHeader from '@/components/BaseHeader';
import FileInput from "../components/Inputs/FileInput";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueMonthlyPicker from "vue-monthly-picker";
import {
  Input,
  Tooltip,
  Popover,
  Select,
  Option,
  Dialog,
  Button,
  Tabs,
  TabPane,
  DatePicker,
  checkbox,
  CheckboxGroup,
  Menu,
  Submenu,
  Form,
  FormItem,
  Drawer,
  Badge,
  TimePicker, TimeSelect,
  Image,
  Tag
} from 'element-ui';
import "./custom-rules"
/**
 * You can register global components here and use them as a plugin in your mainF Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseItemPerPage.name, BaseItemPerPage);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component(Input.name, Input);
    Vue.component(Select.name, Select);
    Vue.component(Option.name, Option);
    Vue.component(Dialog.name, Dialog);
    Vue.component(Button.name, Button);
    Vue.component(checkbox.name, checkbox);
    Vue.component(Tabs.name, Tabs);
    Vue.component(Tag.name, Tag);
    Vue.component(TabPane.name, TabPane);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(TimePicker.name, TimePicker);
    Vue.component(TimeSelect.name, TimeSelect);
    Vue.component(CheckboxGroup.name, CheckboxGroup);
    Vue.component(Menu.name, Menu);
    Vue.component(Submenu.name, Submenu);
    Vue.component(Form.name, Form);
    Vue.component(FormItem.name, FormItem);
    Vue.component(Drawer.name, Drawer);
    Vue.component(FileInput.name, FileInput);
    Vue.component('validation-provider', ValidationProvider)
    Vue.component('validation-observer', ValidationObserver)
    Vue.component( 'editor', Editor)
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.use(Image)
    Vue.component('VueMonthlyPicker', VueMonthlyPicker);
  }
};
window.axios = require('axios');
export default GlobalComponents;
