import axios from 'axios';
import router from "@/routes/router";
import authHelper from "@/util/authHelper";

// Create an Axios instance
const api = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API_URL}`,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export default function setup() {
  axios.interceptors.request.use(function (config) {
    const token = authHelper.getAuthToken('subUser');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, function (err) {
    return Promise.reject(err);
  });

  axios.interceptors.response.use(function (response) {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401) {
      if (!originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axios(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }
        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(async (resolve, reject) => {
          try {
            const response = await api.post(
              `/api/refresh-token`, {},
              {
                headers: {
                  Authorization: `Bearer ${authHelper.getRefreshToken()}`,
                },
              }
            );
            const newAccessToken = response.data.data.accessToken;
            const newRefreshToken = response.data.data.refreshToken;
  
            authHelper.setAuthToken(newAccessToken, "subUser");
            authHelper.setRefreshToken(newRefreshToken, "subUser");
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            isRefreshing = false;
            processQueue(null, newAccessToken);
            resolve(axios(originalRequest));
          } catch (refreshError) {
            isRefreshing = false;
            processQueue(refreshError, null);
            authHelper.deleteAuthToken("subUser");
            authHelper.deleteManagePatientsSavedSearch();
            authHelper.deleteEventCalendarSavedSearch();
            router.push("/login");
            reject(refreshError);
          }
        });
      } else {
        authHelper.deleteAuthToken("subUser");
        authHelper.deleteRefreshToken();
        authHelper.deleteManagePatientsSavedSearch();
        authHelper.deleteEventCalendarSavedSearch();
        isRefreshing = false;
        router.push("/login");
      }
    }
    isRefreshing = false;
    return Promise.reject(error)
  })
}
